<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getTarget: Fandom.getTarget,
    getLangLink(lang) {
      const slug = globalState.pageModel.name_to_content[globalState.pageModel.main_content_name].slug;
      return Fandom.urljoin('/', lang, slug);
    }
  }
};
</script>

<template>
  <div footer-component>
    <nav class="bg-light">
      <div class="container py-5">
        <div class="row">
          <div v-if="content.logo" class="col-12 col-md my-3">
            <img :src="content.logo.url" class="logo"/>
          </div>
          <div v-for="nav in content.navbar" class="col-12 col-md my-3">
            <div v-if="nav.title" class="h4 mb-3">{{ nav.title }}</div>
            <div v-for="item in nav.items" class="my-2">
              <a v-if="item.url" :href="item.url.url" :target="getTarget(item.url)" class="link">
                {{ item.url.title }}
              </a>
              <div v-else-if="item.description" v-html="item.description"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <nav v-if="content.navbar_bottom" class="bg-white">
      <div class="container py-5">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div v-for="item in content.navbar_bottom" class="mr-3">
              <template v-if="item.url">
                <a v-if="item.icon" :href="item.url.url" :target="getTarget(item.url)" class="text-dark">
                  <i :class="item.icon" class="fa-2xl" :title="item.url.title"></i>
                </a>
                <a v-else :href="item.url.url" :target="getTarget(item.url)" class="link">
                  <span class="small">{{ item.url.title }}</span>
                </a>
              </template>
            </div>
          </div>
          <div class="d-flex align-items-center mx-n2">
            <a
              v-for="lang in globalState.pageModel.langs" 
              :href="getLangLink(lang)"
              :class="{'active': globalState.lang == lang}"
              class="link text-uppercase mx-2"
            >
              {{ lang }}
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
[footer-component] {
  .logo {
    width: 10rem;
    height: auto;
  }
}
</style>
