<script>
import { fandomBasicMixin, fandomExtendedMixin, fandomTileMixin } from "src/modules/fandom_mixins_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, fandomTileMixin],
  data() {
    return { };
  },
  computed: {
    icon() {
      return get(this.getDecorator(this.content, "label"), ['icon'], this.content.icon); 
    },
    url() {
      return get(this.content, ['url','url'], Fandom.getContentURL(this.content));
    }
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" tile-article-component>
    <a
      class="deco-none"
      :class="url ? 'cursor-pointer bg-transition' : 'cursor-none'"
      :target="getTarget(content.url)"
      :href="url || 'javascript: void(0)'"
      :style="theme"
    >
      <div class="ar-16by9 heading position-relative overflow-hidden">
        <div class="position-absolute-center bg-cover-center w-100 h-100 d-flex justify-content-center align-items-center" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}">
          <i v-if="icon" :class="icon" class="fa-4x"></i>
        </div>
      </div>
      <div class="py-4">
        <div class="mb-2">
          <component
            v-if="content.title"
            :class="content.title_class || 'h5'"
            :is="content.title_tag || 'h4'"
          >
            {{content.title}}
          </component>
          <component
            v-if="content.subtitle"
            :is="content.subtitle_tag || 'p'"
            :class="content.subtitle_class"
            class="line-clamp-2"
          >
            {{content.subtitle}}
          </component>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-article-component] {
  color: var(--theme-color) !important;

  .cursor-none {
    cursor: default;
  }

  .heading {
    color: $light !important;
  }

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
}
</style>