<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";
import TextSection from 'text-section';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {TextSection},
  data() {
    return {
      paddingYDefault: 5
    };
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getBackgroundColorStyle(column) {
      return {
        "background-color": column.background_color ? column.background_color : null
      }
    },
  },
  computed: {
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
    height() {
      return get(this, ["content", "height"], "$4by3");
    },
    containerStyle() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : false
      };
    },
    rootClasses() {
      return [
        this.contentType,
        this.propagateBackground ? "themed-item" : "",
        this.propagateBackground ? "" : this.paddingY
      ];
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    contentClasses() {
      return [
        this.propagateBackground ? "" : "themed-item",
        this.propagateBackground ? this.paddingY : ""
      ];
    },
    contentStyle() {
      const height = this.height.indexOf("$") !== 0 ? this.height : null;
      return {
        "min-height": this.isMobile ? "auto" : height,
        "background-image": this.propagateBackground ? false : this.backgroundImage
      };
    },
    verticalAlignClass() {
      let align = 'start';
      switch (this.content.vertical_align) {
        case 'center':
          align = 'center';
          break;
        case 'bottom':
          align = 'end';
          break;
      }
      return `justify-content-${align}`;
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    containerClass() {
      const propagateContainer = get(this, ["content", "propagate_container"], false);
      return propagateContainer ? "container-fluid" : "container";
    }
  }
};
</script>

<template>
    <div v-easyadmin="easyadminId" :class="rootClasses" :style="theme" :id="anchorId" two-col-text-component>
      <div class="w-100 bg-cover-center" :style="containerStyle">
        <div class="position-relative overflow-hidden" :class="containerClass">
          <div class="row bg-cover-center" :class="contentClasses" :style="contentStyle">
            <div
              v-for="column in content.columns"
              class="col-12 col-lg d-flex flex-column py-4 text-column"
              :class="verticalAlignClass"
              :style="getBackgroundColorStyle(column)"
            >
              <div class="row justify-content-center">
                <div class="col-12 col-sm-10">
                  <text-section :content="column" :opt-container-content="containerContent"></text-section>
                  <slot name="stripe-two-col-custom-text"></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss" scoped>
@include media-breakpoint-down(md) {
  /* Forced no vertical padding on mobile */
  [two-col-text-component] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

/* IE11 hack */
@include ie {
  div > .row {
    max-height: 1080px;
  }
  .text-column {
    min-height: inherit;
    max-height: 1080px;
  }
}
</style>
