<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      paddingYDefault: 5
    };
  },
  computed: {
    url() {
      return this.content.url?.url || 'javascript:void(0)';
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :id="anchorId" :style="theme" v-if="isContentVisibleToUser()" stripe-infinite-text-component>
    <a :href="url" :target="getTarget(content.url)" class="text-decoration-none">
      <div class="w-100 overflow-hidden themed-item" :class="paddingY">
        <div class="scrolling-items d-flex">
          <div v-for="i in 3" class="text-nowrap d-flex">
            <div v-for="j in 3" class="item d-flex align-items-start mr-5">
              <div class="display-1 item-text">{{ content.text }}</div>
              <i :class="content.icon" class="fa-solid item-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[stripe-infinite-text-component] {

  @keyframes infiniteScroll {
    from { transform: translateX(0) }
    to { transform: translateX(-50%) }
  }

  .scrolling-items {
    animation-name: infiniteScroll;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .item {
      &-icon, &-text {
        font-size: $display1-size;
      }
    }
  }
}
</style>
