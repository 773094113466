<script>
import { mqLess } from 'src/modules/viewport_module';
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      sidebar: false
    };
  },
  methods: {
    isItemActive(item) {
      // When url is a ContentLink extra field type the content name can be used as matching 
      // name when mathing name is missing. 
      if (item.url.content && !item.matching_name) {
        item.matching_name = item.url.content;
      }
      const re = new RegExp(`^(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    },
    toggleSidebar() {
      if (this.sidebar) {
        clearAllBodyScrollLocks();
        this.sidebar = false;
      } else {
        disableBodyScroll(this.$refs['sidebar']);
        this.sidebar = true;
      }
    }
  },
  computed: {
    items() {
      return this.content.items || [];
    },
    logoAlt() {
      return this.pageModel.seo?.title || '';
    },
    isMobile() {
      return mqLess('md');
    }
  },
};
</script>

<template>
  <div class="sticky-top">
    <nav
      id="main-navbar"
      class="navbar navbar-expand-lg justify-content-between bg-white border-bottom"
      navbar-component
    >
      <a :href="applyContextToUrl('/')" class="h-100">
        <img :src="content.logo.url" class="h-100" :alt="logoAlt"/>
      </a>
      <div v-if="!isMobile" class="d-flex align-items-center">
        <div v-for="item in content.items" class="mx-4">
          <a
            v-if="item.url"
            :href="item.url.url"
            :target="getTarget(item.url)"
            class="link h5"
            :class="{'active': isItemActive(item)}"
          >
            {{ item.url.title }}
          </a>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div v-if="content.highlight_url" class="mx-4">
          <a
            :href="content.highlight_url.url"
            :target="getTarget(content.highlight_url)"
            class="link h5"
          >
            {{ content.highlight_url.title }}
          </a>
        </div>
        <div v-if="isMobile || content.sidebar" class="fa-2xl cursor-pointer" @click="toggleSidebar()">
          <i v-if="sidebar" class="fa-regular fa-xmark"></i>
          <i v-else class="fa-regular fa-bars"></i>
        </div>
      </div>
    </nav>
    <transition enter-active-class="animated faster slideInDown" leave-active-class="animated faster slideOutUp">
      <nav v-if="isMobile && sidebar" class="position-absolute w-100 bg-white border-bottom p-3" sidebar-mobile-component>
        <div v-for="item in content.items" class="my-4">
          <a
            v-if="item.url"
            :href="item.url.url"
            :target="getTarget(item.url)"
            class="link h5"
            :class="{'active': isItemActive(item)}"
          >
            {{ item.url.title }}
          </a>
        </div>
      </nav>
    </transition>
    <transition enter-active-class="animated faster slideInRight" leave-active-class="animated faster slideOutRight">
      <nav v-if="!isMobile && sidebar && content.sidebar" class="position-fixed col-4 col-xl-3 bg-white border-left px-0" sidebar-desktop-component>
        <div class="d-flex justify-content-end fa-2xl cursor-pointer mb-3 p-4" @click="toggleSidebar()">
          <i class="fa-regular fa-xmark"></i>
        </div>
        <div class="px-5">
          <div class="h3">{{ content.sidebar.title }}</div>
          <div v-html="content.sidebar.description">
          </div>
        </div>
      </nav>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[navbar-component] {
  height: $header-height;
  z-index: 1;
}

[sidebar-desktop-component] {
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
</style>