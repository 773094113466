<script>
import { viewportMixin } from 'src/modules/viewport_module'
import { rootMixin } from 'src/modules/inline_editing_module.js'
import { fandomRootMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js'
import { fandomAnalyticsStartedMixin } from 'src/modules/fandom_analytics_module.js'
import { ActionCableRootMixin } from "src/modules/websocket_module.js";
import { addRecaptchaInputInRef } from 'src/modules/recaptcha_v3_module.js';

import VueScript from 'vue-script';
import Navbar from 'navbar';
import VueFooter from 'vue-footer';
import VideoPopup from 'video-popup';
import EasyadminControls from 'easyadmin-controls';
import ModalContent from 'modal-content';
import PasswordVisibilitySwitch from 'password-visibility-switch';

export default {
  el: '#vue-root',
  mixins: [fandomRootMixin, rootMixin, viewportMixin, fandomAnalyticsStartedMixin, fandomExtendedMixin, ActionCableRootMixin],
  components: {
    VueScript,
    Navbar,
    VueFooter,
    VideoPopup,
    EasyadminControls,
    ModalContent,
    PasswordVisibilitySwitch
  },
  mounted() {
    Fandom.jsEnabled();
    Fandom.flickityIOsFix([".flickity-carousel"]);

    $("body").css(this.theme);
    this.enableAnchor();
    this.enablePopState();

    addRecaptchaInputInRef(this.$refs["recaptcha-form"]);
  },
  methods: {
    enablePopState() {
      window.onpopstate = function(event) {
        if (event.state.name_to_content) {
          globalState.pageModel.name_to_content = event.state.name_to_content;
        }
      }
    },
    enableAnchor() {
      Vue.nextTick(() => {
        const location = window.location.href;
        if (location.includes("#")) {
          const anchor = location.split("#")[1];
          const el = $(`#${anchor}`);
          if (!!el.length) {
            const position = $(`#${anchor}`).offset().top - $("#main-navbar").outerHeight();

            $("html, body").animate({
              scrollTop: position
            }, 0, "linear");
          }
        }
      });
    }
  },
  computed: {
    content() {
      return this.globalState.pageModel.name_to_content[this.globalState.pageModel.main_content_name];
    }
  }
}
</script>