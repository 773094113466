<template>
  <div
    v-easyadmin="easyadminId"
    :class="[contentType, paddingY]" 
    class="themed-item"
    :style="theme" 
    page-player-component
  >
    <div class="container-fluid" v-if="!countdown">
      <div class="row">
        <div class="col-12 px-0">
          <player
            :content="content.play"
            :opt-container-content="containerContent"
            :current-chapter="chapter"
            :quiz="content.quiz"
            :active-right-content="visibleRightContent"
            :has-active-banners="bannerProducts.length>0"
            close-behaviour="back"
            @player:show-right-content="handleShowRightContent"
            @player:change-right-content="handleShowRightContent"
            @player:hide-right-content="handleHideRightContent"
            @player:show-left-content="handleShowLeftContent"
            @player:time="(time)=>currentTime=time"
            ref="player"
          >
            <template #notifications>
              <player-notifications v-model="notification"></player-notifications>
            </template>
            <template #sub-header>
              <player-banners :products="bannerProducts" :banner-audio="content.banner_audio" :banner-countdown-audio="content.banner_countdown_audio" @banner-clicked="handleBannerClick"></player-banners>
            </template>
            <template v-if="hasComments" #left-content="{ratio}">
              <div class="left-container h-100" :class="`player-ratio-${ratio}`">
                <div class="left-container__content d-flex flex-column border rounded h-100">
                  <div class="left-header border-bottom py-3 py-md-5 px-medium px-md-double w-100">
                    <p class="small fs-xs mb-2">{{ content.title }}<span class="pl-3">{{ startDate }}</span></p>
                    <h4 class="mb-0">{{ ft('globals.live_shopping.comments') }}</h4>
                  </div>
                  <comments 
                    v-if="content.comments && visibleLeftContent===PLAYER_CONTENT.COMMENTS"
                    class="masked-element w-100 flex-grow-1 d-flex flex-column-reverse overflow-auto p-medium p-lg-double"
                    :content="content.comments"
                    :opt-container-content="containerContent"
                    :reply="content.comments.users_reply || loggedUserCanComment"
                    :allow-anonymous-comments="!content.comments.registration_needed"
                    :is-banned="loggedUserBanned"
                    :show-more-button="false"
                    :show-date="false"
                    :showFeedback="false"
                    layout="live"
                    theme="dark">
                  </comments>
                </div>
              </div>
            </template>
            <template #right-content="{ratio, toggleRightContent, isMobileLandscape}">
              <div :style="rightContentStyle" class="h-100">
                <div class="right-container themed-item rounded h-100" :class="`player-ratio-${ratio}`">
                  <div class="hide-sidebar cursor-pointer" @click="toggleRightContent(PLAYER_CONTENT.EMPTY)">
                    <i class="fal fa-chevron-left py-3 py-md-4 px-medium px-lg-double"></i>
                  </div>
                  <event-interactive-sidebar
                    :content="content"
                    :show-tab="visibleRightContent"
                    :detail-content-name="detailContentName"
                    :tabs="rightContentTabs"
                    :show-header="isMobileLandscape && ratio===RATIO.HORIZONTAL"
                    :event-time="currentTime"
                    :opt-container-content="containerContent"
                    @go-to-chapter="handleGoToChapter"
                    @buy-product="handleBuyProduct"
                    @user-interaction-updated="handleUserInteractionUpdate"
                    @active-tab="(data) => {visibleRightContent=data}"
                  ></event-interactive-sidebar>
                </div>
              </div>
            </template>
          </player>
        </div>
      </div>
    </div>
    <player-countdown
      v-else
      v-model="content.start_time"
      :content="countdownContent"
      :opt-container-content="containerContent"
    ></player-countdown>
    <page-live-event-alert v-if="hasAlert" class="modal-generic" v-model="hasAlert" :content="content.alert" :opt-container-content="containerContent"></page-live-event-alert>
  </div>
</template>

<script>
import moment from 'moment';

import { fandomBasicMixin, fandomExtendedMixin, fandomAuthorizationMixin } from 'src/modules/fandom_mixins_module.js';
import flashsaleMixin from 'src/modules/flashsale_module.js';
import { ActionCableComponentsMixin } from 'src/modules/websocket_module.js';
import { PLAYER_CONTENT, RATIO, ACTIONS } from 'src/modules/player/constants';
import { BANNER_FLASHSALE_DEFAULT_DELTA_TIME } from 'src/modules/utils/constants';
import { browser } from 'src/modules/player/utils';
import config from 'src/modules/player/config';
import { Player, PlayerNotifications, PlayerBanners, PlayerCountdown } from 'player';
import Comments from 'comments';
import EventInteractiveSidebar from 'event-interactive-sidebar';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, fandomAuthorizationMixin, ActionCableComponentsMixin, flashsaleMixin],
  components: {
    Player, PlayerNotifications, PlayerBanners, PlayerCountdown,
    EventInteractiveSidebar,
    Comments
  },
  data: function() {
    return {
      visibleRightContent: PLAYER_CONTENT.EMPTY,
      visibleLeftContent: '',
      PLAYER_CONTENT,
      RATIO,
      chapter: null,
      products: [],
      detailContentName: null,
      notification: {},
      currentTime: 0,
      audios: {}
    }
  },
  mounted() {
    this.visibleRightContent = this.content.highlight ?? PLAYER_CONTENT.EMPTY
    this.initProducts();
    this.initAudios();
    this.initSubscriptions();
  },
  methods: {
    initSubscriptions() {
      this.subscribe(
        { channel: "PublicChannel", room: this.containerContent.name, lang: globalState.lang },
        { 
          connected: () => {
            if (this.disconnected[this.containerContent.name]) {
              this.getContentLastVersion(this.content);
              this.disconnected[this.containerContent.name] = false;
            }
          },
          disconnected: () => {this.disconnected[this.containerContent.name] = true},
          received: (data) => this.receivedChannelData(this.containerContent.name, data)
        }
      );
      this.subscribe(
        { channel: "TrackingChannel", room: this.containerContent.name, lang: globalState.lang },
        { received: (data) => {
          if (typeof data.user_ban !== "undefined") {
            Vue.set(globalState.pageModel.user, "banned", data.user_ban);
          }
        }}
      );
    },
    initProducts() {
      this.products = (this.content?.products?.items ?? [])
        .filter(product => product.valid)
        .map(product => {
          const data = {...product, ...Fandom.getContent(product.content)};
          if (this.hasActiveFlashsale(product)) {
            const startWithDelta = moment(product.flashsale_start).add(product?.flashsale_pre_countdown ?? BANNER_FLASHSALE_DEFAULT_DELTA_TIME, "s");
            const showPreFlashsale = moment().isBetween(product.flashsale_start, startWithDelta);
            if (showPreFlashsale) {
              const duration = Math.ceil(moment.duration(startWithDelta.diff(moment())).asSeconds());
              data.showPreFlashsale = showPreFlashsale;
              setTimeout(()=>{
                const content = this.products.find(item => item.$id === data.$id);
                if (content) {
                  content.showPreFlashsale = false;
                }
              }, duration*1000);
            }
          }

          return data;
        });
    },
    initAudios() {
      if (this.content.banner_audio?.url) {
        this.createAudio('banner', this.content.banner_audio.url);
      }

      if (this.content.banner_countdown_audio?.url) {
        this.createAudio('banner_countdown', this.content.banner_countdown_audio.url);
      }
    },
    createAudio(name, url) {
      this.audios[name] = new Audio(url);
      this.audios[name].volume = 0;
      this.audios[name].currentTime = 0;
      this.audios[name].volume = 1;
    },
    handleShowRightContent(content) {
      this.visibleRightContent = content;
    },
    handleHideRightContent() {
      this.handleShowRightContent(PLAYER_CONTENT.EMPTY);
    },
    handleShowLeftContent(data) {
      this.visibleLeftContent = data.content;
    },
    handleGoToChapter(chapter) {
      this.chapter = {...chapter};
      if (this.isMdAndMinor) {
        this.handleHideRightContent();
      }
    },
    handleBuyProduct(product) {
      Fandom.emit('embed.post-message', {action: ACTIONS.PLAYER.BUY, payload: product});
      if (browser.isSafari) {
        this.$refs.player.handleTogglePip();
      }
    },
    handleBannerClick(banner) {
      if (banner.type !== this.visibleRightContent) {
        this.handleShowRightContent(banner.type);
      }

      // NB this is necessary to trigger the navigation after the right tab has been animated
      Vue.nextTick(() => {
        this.detailContentName = banner.name;
      })
    },
    handleUserInteractionUpdate(data) {
      const INTERACTION_TYPES = this.pageModel.constants.INTERACTION_TYPES;
      const interaction = data.interaction;
      switch (interaction.type) {
        case INTERACTION_TYPES.FAVOURITE:
          const userInteractions = data?.contentUserInfo?.id_to_interaction?.[interaction.id]?.user_interaction ?? {};
          const listId = userInteractions?.data?.list_id ?? -1;
          if (listId > 0 && (userInteractions?.favourite_list_ids ?? []).includes(listId)) {
            this.notification = {title: this.ft("globals.live_shopping.notification.add_favourite"), icon: "fa-bookmark fas"};
            Fandom.emit('embed.post-message', {action: ACTIONS.PLAYER.ADD_TO_WISHLIST, payload: data.content});
          } else {
            Fandom.emit('embed.post-message', {action: ACTIONS.PLAYER.REMOVE_FROM_WISHLIST, payload: data.content});
          }
          break;
        default:
          break;
      }
    }
  },
  computed: {
    startDate() {
      moment.locale(this.globalState.lang ?? 'en');
      return this.content.start_time ? moment(this.content.start_time).format("D MMMM - HH:mm") : '';
    },
    mappedProducts() {
      return {...this.content.products, items: this.products};
    },
    bannerProducts() {
      return this.mappedProducts.items?.filter(p => p.show_banner && (p?.seconds ?? 0) <= this.currentTime)
    },
    hasComments() {
      return Object.keys(this.content.comments ?? {}).length>0;
    },
    rightContent() {
      if ([PLAYER_CONTENT.PRODUCTS, PLAYER_CONTENT.WISHLIST].includes(this.visibleRightContent)) {
        return this.mappedProducts;
      } else if (this.visibleRightContent == PLAYER_CONTENT.CHAPTERS) {
        return this.content.play.chapters;
      }
    },
    rightDetailContent() {
      return this.detailContentName ? this.mappedProducts.items.find(item=>item.name === this.detailContentName) : null;
    },
    rightContentStyle() {
      return {
        "--theme-background": this.rightContent?.background_color ?? "white",
        "--theme-color": this.rightContent?.text_color ?? "black",
      }
    },
    rightContentTabs() {
      return config.presets[this.content.play?.preset ?? PRESETS_TYPE.DEFAULT]
        .filter(item => Object.values(PLAYER_CONTENT).includes(item))
    },
    isMdAndMinor() {
      return globalState.viewport.xs || globalState.viewport.sm || globalState.viewport.md;
    },
    hasAlert() {
      return this.content.alert?.valid ?? false;
    },
    countdown() {
      return this.content.countdown?.valid ?? false;
    },
    countdownContent() {
      return {
        ...this.content.countdown,
        title: this.content.title,
        titleClass: this.content.title_class,
        backgroundImage: this.content.play?.background_image ?? ""
      }
    }
  },
  watch: {
    'content.products': {
      handler() {
        this.initProducts();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
[page-player-component] {
  position: relative;
  background-color: var(--theme-background);
  color: var(--theme-color);
  z-index: 1091;

  ::v-deep {
    .fs-small {
      font-size: $font-size-sm; 
    }

    .fs-xs {
      font-size: $font-size-xs
    }

    .hide-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
      
      &::-webkit-scrollbar { display: none; }
    }
  }

  .line-through {
    text-decoration: line-through;
  }

  .rounded {
    border-radius: $player-border-radius !important;

    @media (orientation: portrait) {
      @include media-breakpoint-down(md) {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }

  .left-container {
    &__content, .left-header {
      border-color: #C5C5C959 !important;
    }

    &.player-ratio-16by9 {
      .left-container__content {
        border: none !important;
      }

      .left-header {
        display: none !important;
      }
    }

    @include media-breakpoint-up(lg) {
      &.player-ratio-9by16 {
        .left-container__content {
          background: rgba(0,0,0,.3);
        }
      }
    }

    @include media-breakpoint-down(md) {
      &__content {
        border: none !important;

        .left-header {
          display: none !important;
        }
      }
    }

    ::v-deep {
      [comment-add-component] {
        form {
          width: 100%;
        }
      }
    }
  }

  .right-container {
    position: relative;
    overflow: hidden;

    &.player-ratio-16by9 {
      @media (orientation: landscape) {
        .hide-sidebar {
          i {
            rotate: 180deg;
          }
        }
      }
    }

    .hide-sidebar {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;

      @include media-breakpoint-down(lg) {
        i {
          rotate: -90deg;
        }
      }
    }
  }
}
</style>
