<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      contentFiltering: null
    }
  },
  mounted() {
    if (this.content.$id) {
      console.log(`%Warning\n\tmessage: contents with layout 'grid-filter' should be standalone contents, in order to prevent depth conflicts`, "color: yellow");
    }
  },
  methods: {
    setFilter(filter) {
      this.contentFiltering = filter;

      Fandom.emit('grid-filter-apply', {
        contentFiltering: [this.contentFiltering],
        handlerContents: (this.content.handler_contents || "")
      });
    }
  }
};

</script>

<template>
  <div 
    v-if="isContentVisibleToUser()" 
    v-easyadmin="easyadminId" 
    :class="[contentType, paddingY]"
    grid-filter-component
  >
    <div class="container">
      <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center font-weight-bold small">
        <div
          :class="{'active': !contentFiltering}"
          class="link font-weight-bold mx-3 my-1"
          @click="setFilter()"
        >
          {{ ft('grid_filter.show_all') }}
        </div>
        <div
          v-for="filter in content.filter_contents"
          :class="{'active': contentFiltering == filter}"
          class="link font-weight-bold mx-3 my-1"
          @click="setFilter(filter)"
        >
          {{ getContent(filter).title }}
        </div>
      </div>
    </div> 
  </div>
</template>
